import styled from "@emotion/styled";
import { UIView, VizType } from "../../visualization/Utils";
import { ClearButtonSymbol } from "../mainDropdownPrimitive/styles";
import SettingsDropdown from "./vizSettingsDropdown/SettingsDropdown";
import { SettingOption } from "./vizSettingsDropdown/SettingsDropdown";
import Tooltip from "../../visualization/components/Tooltip";
import { useLocalization } from "@fluent/react";

import RcaSlider from "./RcaSlider";
import { useState } from "react";
import {
  defaultSettingsPerViz,
  primarySelections,
  usePageQueryParams,
} from "../../visualization/defaultSettings";
import { useLocation, useNavigate } from "react-router-dom";

const VizSettingsPanelContainer = styled.div`
  position: absolute;
  z-index: 999;
  width: 300px;
  top: 130px;
  right: 3px;
  bottom: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px #333;
  height: 75vh;
`;

const VizSettingsHeader = styled.div`
  background-color: #334c60;
  display: flex;
  flex-direction: row;
  height: auto;
  border-radius: 10px 10px 0px 0px;
  font-size: 16px;
`;

const SettingsLabelContainer = styled.div`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  padding: 10px 0px;

  & img {
    width: 20px;
    margin-right: 5px;
  }
  color: white;
`;

const VizSettingsBody = styled.div`
  border-top: 1px solid #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
  margin-bottom: 10px;
  background-color: #fff;
`;

const VizSettingsOptionRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 10px 0px;
  padding: 0px 10px;
  align-items: center;
  box-sizing: border-box;
`;

const VizSettingsOptionLabel = styled.div`
  font-size: 12px;
`;

const VizSettingsOptionSelector = styled.div`
  margin-left: auto;
  margin-right: 10px;
  width: 50%;
`;

const DisabledVizSettingsOptionSelector = styled(VizSettingsOptionSelector)`
  opacity: 0.4;
  cursor: not-allowed;
  width: 100%;
  & > * {
    pointer-events: none;
  }
`;

const CloseButton = styled.div`
  margin-left: auto;
  cursor: pointer;
  color: #ffff;
  font-size: 1.6rem;
  font-weight: 300;
  padding: 12px;
`;

const NotAvailableTooltip = styled.div`
  position: absolute;
  top: -90%;
  left: 0px;
  margin-bottom: 100px;
  width: 100%;
  font-size: 0.7rem;
  background-color: #ffffff;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
  z-index: 100;
  box-shadow: 0px 2px 15px #ccc;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #ffffff;
  }
`;

const DisabledVizSettingTooltipContainer = styled.div`
  margin-left: auto;
  margin-right: 10px;
  width: 50%;
  position: relative;

  & > * {
    width: 100%;
  }
`;

const DisabledVizSettingsOption = ({ children }: any) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  let tooltipNotification;
  if (isHovered) {
    tooltipNotification = (
      <NotAvailableTooltip>This feature is not available</NotAvailableTooltip>
    );
  } else {
    tooltipNotification = null;
  }

  return (
    <DisabledVizSettingTooltipContainer
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      {children}
      {tooltipNotification}
    </DisabledVizSettingTooltipContainer>
  );
};

const DefaultSettingsButton = styled.button`
  appearance: none;
  padding: 8px 10px;
  border: 1px solid #5d5d5d;
  background: none;
  margin: 10px 0px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 15px;
  &:hover {
    background: #cccccc;
  }
`;
// const ServicesClassSelector = () => {
//   return (
//     <VizSettingsOptionRow>
//       <VizSettingsOptionLabel>
//         <Tooltip explanation="" title="Services Class" inlineTooltip={true}>
//           Service Class
//         </Tooltip>
//       </VizSettingsOptionLabel>
//       <VizSettingsOptionSelector>
//         <SettingsDropdown setting={SettingOption.ServicesClass} />
//       </VizSettingsOptionSelector>
//     </VizSettingsOptionRow>
//   );
// };

const SettingsPanel = ({ vizType, toggleSettingsPanel }: any) => {
  const { l10n } = useLocalization();
  const [query] = usePageQueryParams();
  const { view: currentUiView } = query;
  const navigate = useNavigate();
  const location = useLocation();
  let options = null;
  const defaultParams = new URLSearchParams(
    Object.entries(query).filter(
      ([k, v]) =>
        primarySelections.includes(k) &&
        defaultSettingsPerViz[vizType][k].default !== v,
    ),
  );
  let defaultSettingsAction = () =>
    navigate(`${location.pathname}?${defaultParams.toString()}`);

  if (vizType === VizType.Tree) {
    let detailLevelSelector;
    let colorBySelector = null;
    if (currentUiView === UIView.Products) {
      detailLevelSelector = (
        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation={l10n.getString(
                "settings-productDetailLevelSelector-tooltipText",
              )}
              title={l10n.getString(
                "settings-productDetailLevelSelector-title",
              )}
              inlineTooltip={true}
            >
              {l10n.getString("settings-productDetailLevelSelector-title")}
            </Tooltip>
            {/* Detail Level */}
          </VizSettingsOptionLabel>
          <VizSettingsOptionSelector>
            <SettingsDropdown setting={SettingOption.ProductLevel} />
          </VizSettingsOptionSelector>
        </VizSettingsOptionRow>
      );

      // Color by complexity is only available at product 4-digit level, since
      // complexity is only computed for 4-digit products
      colorBySelector = (
        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation={
                "Color cells in the treemap by sector or product complexity"
              }
              title={"Color by"}
              inlineTooltip={true}
            >
              {"Color by"}
            </Tooltip>
          </VizSettingsOptionLabel>
          <VizSettingsOptionSelector>
            <SettingsDropdown setting={SettingOption.ColorBy} />
          </VizSettingsOptionSelector>
        </VizSettingsOptionRow>
      );
    } else if (currentUiView === UIView.Markets) {
      detailLevelSelector = (
        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation={l10n.getString(
                "settings-locationDetailLevelSelector-tooltipText",
              )}
              title={l10n.getString(
                "settings-locationDetailLevelSelector-title",
              )}
              inlineTooltip={true}
            >
              {l10n.getString("settings-locationDetailLevelSelector-title")}
            </Tooltip>
          </VizSettingsOptionLabel>
          <VizSettingsOptionSelector>
            <SettingsDropdown setting={SettingOption.LocationDetailLevel} />
          </VizSettingsOptionSelector>
        </VizSettingsOptionRow>
      );
    }

    options = (
      <>
        {detailLevelSelector}
        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation={l10n.getString(
                "settings-tradeFlowSelector-tooltipText",
              )}
              title={l10n.getString("settings-tradeFlowSelector-title")}
              inlineTooltip={true}
            >
              {l10n.getString("settings-tradeFlowSelector-title")}
            </Tooltip>
          </VizSettingsOptionLabel>
          <VizSettingsOptionSelector>
            <SettingsDropdown setting={SettingOption.TradeFlow} />
          </VizSettingsOptionSelector>
        </VizSettingsOptionRow>
        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation={l10n.getString(
                "settings-productClassSelector-tooltipText",
              )}
              title={l10n.getString("settings-productClassSelector-title")}
              inlineTooltip={true}
            >
              {l10n.getString("settings-productClassSelector-title")}
            </Tooltip>
          </VizSettingsOptionLabel>
          <VizSettingsOptionSelector>
            <SettingsDropdown setting={SettingOption.ProductClass} />
          </VizSettingsOptionSelector>
        </VizSettingsOptionRow>
        {/* <ServicesClassSelector /> */}
        {colorBySelector}
        <DefaultSettingsButton onClick={defaultSettingsAction}>
          Default Settings
        </DefaultSettingsButton>
      </>
    );
  } else if (vizType === VizType.Geo) {
    options = (
      <>
        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation={l10n.getString(
                "settings-tradeFlowSelector-tooltipText",
              )}
              title={l10n.getString("settings-tradeFlowSelector-title")}
              inlineTooltip={true}
            >
              {l10n.getString("settings-tradeFlowSelector-title")}
            </Tooltip>
          </VizSettingsOptionLabel>
          <VizSettingsOptionSelector>
            <SettingsDropdown setting={SettingOption.TradeFlow} />
          </VizSettingsOptionSelector>
        </VizSettingsOptionRow>
        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation={l10n.getString(
                "settings-productClassSelector-tooltipText",
              )}
              title={l10n.getString("settings-productClassSelector-title")}
              inlineTooltip={true}
            >
              {l10n.getString("settings-productClassSelector-title")}
            </Tooltip>
          </VizSettingsOptionLabel>
          <VizSettingsOptionSelector>
            <SettingsDropdown setting={SettingOption.ProductClass} />
          </VizSettingsOptionSelector>
        </VizSettingsOptionRow>
        <DefaultSettingsButton onClick={defaultSettingsAction}>
          Default Settings
        </DefaultSettingsButton>
      </>
    );
  } else if (vizType === VizType.OverTime) {
    let detailLevelSelector;
    if (currentUiView === UIView.Products) {
      detailLevelSelector = (
        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation={l10n.getString(
                "settings-productDetailLevelSelector-tooltipText",
              )}
              title={l10n.getString(
                "settings-productDetailLevelSelector-title",
              )}
              inlineTooltip={true}
            >
              {l10n.getString("settings-productDetailLevelSelector-title")}
            </Tooltip>
          </VizSettingsOptionLabel>
          <VizSettingsOptionSelector>
            <SettingsDropdown setting={SettingOption.ProductLevel} />
          </VizSettingsOptionSelector>
        </VizSettingsOptionRow>
      );
    } else if (currentUiView === UIView.Markets) {
      detailLevelSelector = (
        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation={l10n.getString(
                "settings-locationDetailLevelSelector-tooltipText",
              )}
              title={l10n.getString(
                "settings-locationDetailLevelSelector-title",
              )}
              inlineTooltip={true}
            >
              {l10n.getString("settings-locationDetailLevelSelector-title")}
            </Tooltip>
          </VizSettingsOptionLabel>
          <VizSettingsOptionSelector>
            <SettingsDropdown setting={SettingOption.LocationDetailLevel} />
          </VizSettingsOptionSelector>
        </VizSettingsOptionRow>
      );
    }

    options = (
      <>
        {detailLevelSelector}
        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation={l10n.getString(
                "settings-tradeFlowSelector-tooltipText",
              )}
              title={l10n.getString("settings-tradeFlowSelector-title")}
              inlineTooltip={true}
            >
              {l10n.getString("settings-tradeFlowSelector-title")}
            </Tooltip>
          </VizSettingsOptionLabel>
          <VizSettingsOptionSelector>
            <SettingsDropdown setting={SettingOption.TradeFlow} />
          </VizSettingsOptionSelector>
        </VizSettingsOptionRow>
        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation={l10n.getString(
                "settings-productClassSelector-tooltipText",
              )}
              title={l10n.getString("settings-productClassSelector-title")}
              inlineTooltip={true}
            >
              {l10n.getString("settings-productClassSelector-title")}
            </Tooltip>
          </VizSettingsOptionLabel>
          <VizSettingsOptionSelector>
            <SettingsDropdown setting={SettingOption.ProductClass} />
          </VizSettingsOptionSelector>
        </VizSettingsOptionRow>
        {/* <ServicesClassSelector /> */}
        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation={l10n.getString(
                "settings-overTimeLayout-tooltipText",
              )}
              title={l10n.getString("settings-overTimeLayout-title")}
              inlineTooltip={true}
            >
              {l10n.getString("settings-overTimeLayout-title")}
            </Tooltip>
          </VizSettingsOptionLabel>
          <VizSettingsOptionSelector>
            <SettingsDropdown setting={SettingOption.OverTimeLayout} />
          </VizSettingsOptionSelector>
        </VizSettingsOptionRow>
        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation={l10n.getString(
                "settings-overTimeOrdering-tooltipText",
              )}
              title={l10n.getString("settings-overTimeOrdering-title")}
              inlineTooltip={true}
            >
              {l10n.getString("settings-overTimeOrdering-title")}
            </Tooltip>
          </VizSettingsOptionLabel>
          <VizSettingsOptionSelector>
            <SettingsDropdown setting={SettingOption.OverTimeOrdering} />
          </VizSettingsOptionSelector>
        </VizSettingsOptionRow>
        <DefaultSettingsButton onClick={defaultSettingsAction}>
          Default Settings
        </DefaultSettingsButton>
      </>
    );
  } else if (vizType === VizType.Feasibility) {
    let detailLevelSelector;

    detailLevelSelector = (
      <VizSettingsOptionRow>
        <VizSettingsOptionLabel>
          <Tooltip
            explanation={l10n.getString(
              "settings-productDetailLevelSelector-tooltipText",
            )}
            title={l10n.getString("settings-productDetailLevelSelector-title")}
            inlineTooltip={true}
          >
            {l10n.getString("settings-productDetailLevelSelector-title")}
          </Tooltip>
        </VizSettingsOptionLabel>
        <VizSettingsOptionSelector>
          <SettingsDropdown setting={SettingOption.ProductLevel} />
        </VizSettingsOptionSelector>
      </VizSettingsOptionRow>
    );

    options = (
      <>
        {detailLevelSelector}
        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation={l10n.getString(
                "settings-productClassSelector-tooltipText",
              )}
              title={l10n.getString("settings-productClassSelector-title")}
              inlineTooltip={true}
            >
              {l10n.getString("settings-productClassSelector-title")}
            </Tooltip>
          </VizSettingsOptionLabel>
          <VizSettingsOptionSelector>
            <SettingsDropdown setting={SettingOption.ProductClass} />
          </VizSettingsOptionSelector>
        </VizSettingsOptionRow>
        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation="Displays a country's potential production (light coloring) or when turned off, displays a country's current production (dark coloring)"
              inlineTooltip={true}
            >
              Hide Exports (Spotlight)
            </Tooltip>
          </VizSettingsOptionLabel>
          <VizSettingsOptionSelector>
            <SettingsDropdown setting={SettingOption.HideExports} />
          </VizSettingsOptionSelector>
        </VizSettingsOptionRow>

        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation="Size product nodes by world trade or uniformly"
              inlineTooltip={true}
            >
              Sizing
            </Tooltip>
          </VizSettingsOptionLabel>
          <VizSettingsOptionSelector>
            <SettingsDropdown setting={SettingOption.Sizing} />
          </VizSettingsOptionSelector>
        </VizSettingsOptionRow>

        <DefaultSettingsButton onClick={defaultSettingsAction}>
          Default Settings
        </DefaultSettingsButton>
      </>
    );
  } else if (vizType === VizType.ProductSpace) {
    let detailLevelSelector;

    detailLevelSelector = (
      <VizSettingsOptionRow>
        <VizSettingsOptionLabel>
          <Tooltip
            explanation={l10n.getString(
              "settings-productDetailLevelSelector-tooltipText",
            )}
            title={l10n.getString("settings-productDetailLevelSelector-title")}
            inlineTooltip={true}
          >
            {l10n.getString("settings-productDetailLevelSelector-title")}
          </Tooltip>
        </VizSettingsOptionLabel>
        <VizSettingsOptionSelector>
          <SettingsDropdown setting={SettingOption.ProductLevel} />
        </VizSettingsOptionSelector>
      </VizSettingsOptionRow>
    );

    options = (
      <>
        {detailLevelSelector}
        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation={
                "Color points by sector, product complexity, or cluster"
              }
              title={"Color by"}
              inlineTooltip={true}
            >
              {"Color by"}
            </Tooltip>
          </VizSettingsOptionLabel>
          <VizSettingsOptionSelector>
            <SettingsDropdown setting={SettingOption.ColorBy} />
          </VizSettingsOptionSelector>
        </VizSettingsOptionRow>
        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation={l10n.getString(
                "settings-productClassSelector-tooltipText",
              )}
              title={l10n.getString("settings-productClassSelector-title")}
              inlineTooltip={true}
            >
              {l10n.getString("settings-productClassSelector-title")}
            </Tooltip>
          </VizSettingsOptionLabel>
          <VizSettingsOptionSelector>
            <SettingsDropdown setting={SettingOption.ProductClass} />
          </VizSettingsOptionSelector>
        </VizSettingsOptionRow>
        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation="Size product nodes by world trade or uniformly"
              inlineTooltip={true}
            >
              Sizing
            </Tooltip>
          </VizSettingsOptionLabel>
          <VizSettingsOptionSelector>
            <SettingsDropdown setting={SettingOption.Sizing} />
          </VizSettingsOptionSelector>
        </VizSettingsOptionRow>
        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation="Filter products by their Revealed Comparative Advantage (RCA) value.  An RCA > 1 means a country exports more than that product's share in total world trade."
              inlineTooltip={true}
            >
              RCA Threshold
            </Tooltip>
          </VizSettingsOptionLabel>
          <VizSettingsOptionSelector>
            <RcaSlider />
          </VizSettingsOptionSelector>
        </VizSettingsOptionRow>

        <DefaultSettingsButton onClick={defaultSettingsAction}>
          Default Settings
        </DefaultSettingsButton>
      </>
    );
  } else if (vizType === VizType.MarketShare) {
    let detailLevelSelector = (
      <VizSettingsOptionRow>
        <VizSettingsOptionLabel>
          <Tooltip
            explanation={l10n.getString(
              "settings-productDetailLevelSelector-tooltipText",
            )}
            title={l10n.getString("settings-productDetailLevelSelector-title")}
            inlineTooltip={true}
          >
            {l10n.getString("settings-productDetailLevelSelector-title")}
          </Tooltip>
          {/* Detail Level */}
        </VizSettingsOptionLabel>
        <DisabledVizSettingsOption>
          <DisabledVizSettingsOptionSelector>
            <SettingsDropdown setting={SettingOption.ProductLevel} />
          </DisabledVizSettingsOptionSelector>
        </DisabledVizSettingsOption>
      </VizSettingsOptionRow>
    );

    options = (
      <>
        {detailLevelSelector}
        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation={l10n.getString(
                "settings-tradeFlowSelector-tooltipText",
              )}
              title={l10n.getString("settings-tradeFlowSelector-title")}
              inlineTooltip={true}
            >
              {l10n.getString("settings-tradeFlowSelector-title")}
            </Tooltip>
          </VizSettingsOptionLabel>
          <DisabledVizSettingsOption>
            <DisabledVizSettingsOptionSelector>
              <SettingsDropdown setting={SettingOption.TradeFlow} />
            </DisabledVizSettingsOptionSelector>
          </DisabledVizSettingsOption>
        </VizSettingsOptionRow>
        <VizSettingsOptionRow>
          <VizSettingsOptionLabel>
            <Tooltip
              explanation={l10n.getString(
                "settings-productClassSelector-tooltipText",
              )}
              title={l10n.getString("settings-productClassSelector-title")}
              inlineTooltip={true}
            >
              {l10n.getString("settings-productClassSelector-title")}
            </Tooltip>
          </VizSettingsOptionLabel>
          <DisabledVizSettingsOption>
            <DisabledVizSettingsOptionSelector>
              <SettingsDropdown setting={SettingOption.ProductClass} />
            </DisabledVizSettingsOptionSelector>
          </DisabledVizSettingsOption>
        </VizSettingsOptionRow>
        {/* <ServicesClassSelector /> */}
      </>
    );
  }

  return (
    <VizSettingsPanelContainer>
      <VizSettingsHeader>
        <SettingsLabelContainer>Vizualization Settings</SettingsLabelContainer>
        <CloseButton onClick={toggleSettingsPanel}>
          {ClearButtonSymbol}
        </CloseButton>
      </VizSettingsHeader>
      <VizSettingsBody>{options}</VizSettingsBody>
    </VizSettingsPanelContainer>
  );
};

export default SettingsPanel;
