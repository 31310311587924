import React, {
  Children,
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import ReactDOM from "react-dom";
import styled from "@emotion/styled";

import {
  backgroundDark,
  lightBorderColor,
  tooltipHeaderBackgroundColor,
} from "./styles";
import {
  TradeDirection,
  UIView,
  formatAsPercentage,
  formatTotalValue,
  overlayPortalContainerId,
  getColorMap,
  ProductClass,
  ProductLevel,
  VizType,
  ColorBy,
  computeMonetaryValueByTradeFlow,
  TradeFlow,
  getLocationQualifiers,
} from "../Utils";
import { useAppSelector } from "../../store/hooks";
import { bisectCenter, format, index, pointer, range } from "d3";
import { useLocalization } from "@fluent/react";
import complexityColorScale from "../charts/treemap/complexityColorScale";
import { OverTimeLayoutOption } from "../charts/overtime/Utils";
import { useLocation } from "react-router-dom";
import { usePageQueryParams } from "../defaultSettings";
import { worldGroupDatum } from "../../graphql/queries/getLocationsMetadata";
import { allProductsDatum } from "../../graphql/queries/getProductsMetadata";
import useFetchMetadata, {
  MetadataFetchType,
} from "../../sharedUtilities/useFetchMetadata";

export enum TooltipPosition {
  Automatic = "automatic",
  Bottom = "bottom",
  Right = "right",
}

export enum TooltipTheme {
  Light = "light",
  Dark = "dark",
}

const farEndOfScreenToggleClass = "tooltip-at-right-end-of-screen";
export const arrowContainerClassName = "tooltip-arrow-container-class";
const flipArrowClassName = "tooltip-arrow-flip-side-class";
const leftArrowClassName = "tooltip-arrow-point-left-class";
const rightArrowClassName = "tooltip-arrow-point-right-class";

const Root = styled.span`
  cursor: help;
  width: 0.7rem;
  height: 0.7rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10000px;
  margin: 0 0.4rem;
`;

const MoreInformationI = styled.span`
  display: inline-block;
  width: 0.7rem;
  height: 0.7rem;
  line-height: 0;

  svg {
    width: 100%;
    height: 100%;

    circle {
      fill: ${backgroundDark};
    }

    path {
      fill: #fff;
    }
  }
`;

const TooltipBase = styled.div<{
  $theme: TooltipTheme | undefined;
  $overrideStyles: boolean | undefined;
  $isFixed: boolean;
}>`
  position: fixed;
  z-index: 3000;
  width: 250px;
  font-size: 0.7rem;
  line-height: 1.4;
  text-transform: none;
  ${({ $overrideStyles }) =>
    $overrideStyles ? "padding-bottom: 0.5rem;" : "padding: 0.5rem;"}
  opacity: 0;
  transition: opacity 0.15s ease;
  color: ${backgroundDark};
  background-color: ${({ $theme }) =>
    $theme === TooltipTheme.Dark ? backgroundDark : "rgba(255,255,255,0.9)"};
  color: ${({ $theme }) =>
    $theme === TooltipTheme.Dark ? "#fff" : backgroundDark};
  border: 1px solid
    ${({ $theme }) =>
      $theme === TooltipTheme.Dark ? backgroundDark : lightBorderColor};
  border-radius: 4px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  pointer-events: ${({ $isFixed }) => ($isFixed ? "all" : "none")};
  box-sizing: border-box;

  &.${farEndOfScreenToggleClass} .${arrowContainerClassName} {
    justify-content: flex-end;
    padding-right: 0.7rem;
    box-sizing: border-box;
  }

  &.${flipArrowClassName} .${arrowContainerClassName} {
    transform: translate(0, -100%);
    top: 0;

    div {
      &:before {
        top: -1px;
        transform: rotate(180deg);
      }

      &:after {
        transform: rotate(180deg);
      }
    }
  }

  &.${leftArrowClassName} .${arrowContainerClassName} {
    transform: translate(-50%, 0);
    top: 50%;
    left: -6px;

    div {
      &:before {
        top: -1px;
        transform: rotate(90deg);
      }

      &:after {
        transform: rotate(90deg);
      }
    }
  }

  &.${rightArrowClassName} .${arrowContainerClassName} {
    transform: translate(50%, 0);
    top: 50%;
    right: -4px;

    div {
      &:before {
        top: -1px;
        transform: rotate(-90deg);
      }

      &:after {
        transform: rotate(-90deg);
      }
    }
  }
`;

const ArrowContainer = styled.div<{ $position: TooltipPosition | undefined }>`
  width: 100%;
  height: 0.5rem;
  display: flex;
  justify-content: center;
  position: absolute;
  transform: ${({ $position }) => {
    if ($position === TooltipPosition.Bottom) {
      return "translate(0, -100%)";
    } else if ($position === TooltipPosition.Right) {
      return "translate(-100%, 0)";
    } else {
      return "translate(0, 100%)";
    }
  }};
  top: ${({ $position }) => {
    if ($position === TooltipPosition.Bottom) {
      return "0";
    } else if ($position === TooltipPosition.Right) {
      return "";
    } else {
      return "";
    }
  }};
`;

const Arrow = styled.div<{
  $theme: TooltipTheme | undefined;
  $position: TooltipPosition | undefined;
}>`
  width: 0.5rem;
  height: 0.5rem;
  position: relative;
  z-index: -1;
  display: flex;
  justify-content: center;
  transform: translate(-50%, 0);

  &:before {
    content: "";
    position: absolute;
    top: ${({ $position }) =>
      $position === TooltipPosition.Bottom ? "-1px" : "0"};
    left: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid
      ${({ $theme }) =>
        $theme === TooltipTheme.Dark
          ? backgroundDark
          : "rgba(255,255,255,0.9)"};
    ${({ $position }) =>
      $position === TooltipPosition.Bottom ? "transform: rotate(180deg);" : ""}
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 1px;
  }
`;

const GenericSpan = styled.span`
  cursor: help;
  display: contents;
`;

const GenericSpanInline = styled(GenericSpan)`
  border-bottom: 1px dotted #000000;
  display: inline-block;
`;

let timeout: number;

interface Props {
  explanation: React.ReactNode | null;
  children?: React.ReactNode;
  cursor?: string;
  theme?: TooltipTheme;
  tooltipPosition?: TooltipPosition;
  overrideStyles?: boolean;
  delay?: number;
  title?: string | undefined;
  inlineTooltip?: boolean | undefined;
  titleTooltip?: boolean | undefined;
  isFixed?: boolean | undefined;
  isFixedCoords?: [number, number] | undefined;
  snapToPosition?: boolean | undefined;
  vizType?: VizType | undefined;
  clearHighlightedItem?: () => void | undefined;
}

export const TooltipContentsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const TooltipHeader = styled.div`
  width: 100%;
  padding: 15px;
  background-color: ${tooltipHeaderBackgroundColor};
  box-sizing: border-box;
  font-size: 14px;
`;

export const ProductCodeLabel = styled.span`
  font-size: 12px;
  color: #666666;
`;

export const TooltipBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-sizing: border-box;
  font-size: 12px;
`;

export const TooltipBodyRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3px;
`;

export const TooltipBodyRowLabel = styled.div`
  width: 75%;
`;

export const TooltipBodyRowValue = styled.div`
  font-weight: bold;
  margin-left: auto;
`;

export const TooltipExploreMoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 12px;
  & h2 {
    font-size: inherit;
    font-style: italic;
    display: block;
    flex: 1 0 100%;
    font-weight: unset;
    margin-bottom: 4px;
    margin-top: 0px;
  }
`;

export const TooltipExploreMoreButton = styled.button`
  appearance: none;
  padding: 8px;
  margin: 2px;
  background-color: #334c60;
  color: #ffffff;
  border: none;
  font-family: inherit;
  cursor: pointer;
  margin-right: 4px;
  width: 100%;
  &:hover {
    background-color: #567388;
  }
`;

export const SectorLabelWithColorIcon = styled.div<{
  $fillColor: string | undefined;
}>`
  font-weight: inherit;
  display: flex;
  align-items: center;
  width: max-content;

  &::before {
    display: ${({ $fillColor }) => ($fillColor ? `inline-block` : `none`)};
    width: 12px;
    height: 12px;
    content: "";
    background-color: ${({ $fillColor }) =>
      $fillColor ? $fillColor : `#CCCCCC`};
    margin-right: 3px;
  }
`;

export const SectorColorDot = styled.span<{ $fillColor: string }>`
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 0.33rem;
  border-radius: 50%;
  background-color: ${({ $fillColor }) => $fillColor};
`;

export const ShowMoreLessButton = styled.div`
  cursor: pointer;
  margin: 4px 0px 0px 0px;
`;

const tooltipElementReferenceId = "TooltipCaptureContainer";

const MarkerLine = styled.span`
  position: absolute;
  margin-left: -1px;
  width: 2px;
  height: auto;
  background-color: #333333;
  pointer-events: none;
  border-radius: 10px;
  content: "";
`;

export const determineClosestXValue = ({
  e,
  xScale,
  containerSize,
  chartMargin,
  setXAxisTooltipValue,
}: {
  e: React.MouseEvent<SVGElement>;
  xScale: any;
  containerSize: { width: number; height: number } | undefined;
  setXAxisTooltipValue: any;
  chartMargin: any;
}) => {
  if (!containerSize) return;

  let [x, y] = pointer(e.nativeEvent, e.nativeEvent.target);
  // Ensure x and y are within the chart bounds
  x = Math.max(
    chartMargin.left,
    Math.min(x, containerSize.width - chartMargin.right),
  );
  y = Math.max(
    chartMargin.top,
    Math.min(y, containerSize.height - chartMargin.bottom),
  );

  let [xScaleStart, xScaleEnd] = xScale.domain();
  let yearsRange = range(xScaleStart, xScaleEnd + 1, 1);
  let inverted = xScale.invert(x);
  let closestXScaleIndex = bisectCenter(yearsRange, inverted);
  let closestXScaleYearValue = yearsRange[closestXScaleIndex];

  setXAxisTooltipValue({
    year: closestXScaleYearValue,
    x: xScale(closestXScaleYearValue),
    y,
  });
};

export const TooltipLineMarker = ({ year, x, yAxisMarkerExtent }: any) => {
  if (year && x) {
    let yMin, yMax;
    if (year && x && yAxisMarkerExtent) {
      yMin = yAxisMarkerExtent.min;
      yMax = yAxisMarkerExtent.max;

      let y1 = yMin + 10; // NOTE: The y-positions here are offset by +10 pixels due to padding-top of the parent <div> being 10px
      let y2 = yMax + 10;

      let style = {
        top: `${y2}px`,
        left: `${x}px`,
        height: `${y1 - y2}px`,
      };

      return <MarkerLine style={style} />;
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
};

export const TooltipContent = memo(
  ({ datum, view, totalValue, tradeDirection, productClass }: any) => {
    const [
      {
        tradeFlow: currentTradeFlow,
        colorBy: currentColorBySelection,
        layout: currentOverTimeLayout,
        importer,
        exporter,
      },
      setQuery,
    ] = usePageQueryParams();

    const { l10n } = useLocalization();
    const location = useLocation();
    const currentVizType = location.pathname.split("/")[2] as any;
    const [showMore, setShowMore] = useState<boolean>(false);

    let tooltipBodyContents;
    let tooltipHeader;
    let exploreMoreLabel;
    let netGrossLabelModifier;

    if (currentTradeFlow === TradeFlow.Gross) {
      netGrossLabelModifier = "Gross";
    } else if (currentTradeFlow === TradeFlow.Net) {
      netGrossLabelModifier = "Net";
    }

    let productsMetadataSelector: string | undefined;
    if (productClass === ProductClass.HS92Products) {
      productsMetadataSelector = "productsHs92";
    } else if (productClass === ProductClass.HS12Products) {
      productsMetadataSelector = "productsHs12";
    } else if (productClass === ProductClass.SITCProducts) {
      productsMetadataSelector = "productsSitc";
    }

    let productMetadata = useAppSelector((state) =>
      productsMetadataSelector
        ? state.sharedData[productsMetadataSelector]
        : undefined,
    );
    const { metadata: countryMetadata } = useFetchMetadata({
      metadataFetchType: MetadataFetchType.Location,
    });
    const { exporterIsWorld, importerIsWorld } = getLocationQualifiers({
      exporter,
      importer,
    });
    const locationLookup = index(
      Object.values(countryMetadata).flat(),
      (d: any) => (d.countryId ? d.countryId : d.groupId),
    ) as any;

    if (view === UIView.Markets) {
      let { nameEn, groupName } = datum;
      if (groupName) {
        tooltipHeader = groupName;
      } else {
        tooltipHeader = nameEn;
      }
    } else if (view === UIView.Products) {
      tooltipHeader = (
        <>
          {datum.nameEn + " "}
          <ProductCodeLabel>
            | {datum.productCode} {productClass}
          </ProductCodeLabel>
        </>
      );
    }

    if (datum) {
      if (view === UIView.Markets) {
        let {
          groupId,
          partnerGroupId,
          nameEn,
          groupName,
          exportValue,
          importValue,
          year,
          shareForYear,
          productId,
          id: countryId,
        } = datum;
        if (groupName) {
          tooltipHeader = groupName;
        } else {
          tooltipHeader = nameEn;
        }

        let valueToUse;
        let labelToUse;
        let valueFormatterToUse;

        if (currentVizType === VizType.OverTime) {
          if (currentOverTimeLayout === OverTimeLayoutOption.Share) {
            if (shareForYear !== undefined) {
              valueToUse = shareForYear;
              labelToUse = "Share";
              valueFormatterToUse = format(".2%");
            } else {
              valueToUse = undefined;
              labelToUse = undefined;
              valueFormatterToUse = undefined;
            }
          } else {
            if (tradeDirection === TradeDirection.Exports) {
              valueToUse = exportValue;
              labelToUse = netGrossLabelModifier
                ? `${netGrossLabelModifier} ${
                    exporterIsWorld && importer === ""
                      ? "Export Value"
                      : importerIsWorld && exporter === ""
                        ? "Import Value"
                        : exporterIsWorld
                          ? "Import Value"
                          : "Export Value"
                  }`
                : exporterIsWorld && importer === ""
                  ? "Export Value"
                  : importerIsWorld && exporter === ""
                    ? "Import Value"
                    : exporterIsWorld
                      ? "Import Value"
                      : "Export Value";
            } else if (tradeDirection === TradeDirection.Imports) {
              valueToUse = importValue;
              labelToUse = netGrossLabelModifier
                ? `${netGrossLabelModifier} ${
                    exporterIsWorld && importer === ""
                      ? "Export Value"
                      : importerIsWorld && exporter === ""
                        ? "Import Value"
                        : exporterIsWorld
                          ? "Import Value"
                          : "Export Value"
                  }`
                : exporterIsWorld && importer === ""
                  ? "Export Value"
                  : importerIsWorld && exporter === ""
                    ? "Import Value"
                    : exporterIsWorld
                      ? "Import Value"
                      : "Export Value";
            }
            valueFormatterToUse = (value: any) => "$" + formatTotalValue(value);
          }
        } else {
          if (tradeDirection === TradeDirection.Exports) {
            valueToUse = computeMonetaryValueByTradeFlow({
              datum,
              tradeFlow: currentTradeFlow,
              tradeDirection,
            });
            labelToUse = netGrossLabelModifier
              ? `${netGrossLabelModifier} ${
                  exporterIsWorld && importer === ""
                    ? "Export Value"
                    : importerIsWorld && exporter === ""
                      ? "Import Value"
                      : exporterIsWorld
                        ? "Import Value"
                        : "Export Value"
                }`
              : exporterIsWorld && importer === ""
                ? "Export Value"
                : importerIsWorld && exporter === ""
                  ? "Import Value"
                  : exporterIsWorld
                    ? "Import Value"
                    : "Export Value";
          } else if (tradeDirection === TradeDirection.Imports) {
            valueToUse = computeMonetaryValueByTradeFlow({
              datum,
              tradeFlow: currentTradeFlow,
              tradeDirection,
            });
            labelToUse = netGrossLabelModifier
              ? `${netGrossLabelModifier} ${
                  exporterIsWorld && importer === ""
                    ? "Export Value"
                    : importerIsWorld && exporter === ""
                      ? "Import Value"
                      : exporterIsWorld
                        ? "Import Value"
                        : "Export Value"
                }`
              : exporterIsWorld && importer === ""
                ? "Export Value"
                : importerIsWorld && exporter === ""
                  ? "Import Value"
                  : exporterIsWorld
                    ? "Import Value"
                    : "Export Value";
          }
          valueFormatterToUse = (value: any) => "$" + formatTotalValue(value);
        }

        exploreMoreLabel = "For this location explore:";

        if (
          currentVizType === VizType.OverTime &&
          (countryId || groupId) &&
          year
        ) {
          // This tooltip case is for Over Time chart, where we have both location ID and year to display
          tooltipBodyContents = (
            <>
              <TooltipBodyRow>
                <TooltipBodyRowLabel>{labelToUse}:</TooltipBodyRowLabel>
                <TooltipBodyRowValue>
                  {valueFormatterToUse && valueFormatterToUse(valueToUse)}
                </TooltipBodyRowValue>
              </TooltipBodyRow>
              <TooltipBodyRow>
                <TooltipBodyRowLabel>Year:</TooltipBodyRowLabel>
                <TooltipBodyRowValue>{year}</TooltipBodyRowValue>
              </TooltipBodyRow>
            </>
          );
        } else {
          if (!valueToUse) {
            tooltipBodyContents = (
              <>
                <TooltipBodyRow>
                  <TooltipBodyRowLabel>No data available</TooltipBodyRowLabel>
                </TooltipBodyRow>
              </>
            );
          } else {
            const lid = countryId || groupId;
            const locationName =
              locationLookup.get(lid)?.nameEn ||
              locationLookup.get(lid)?.groupName ||
              "the World";

            tooltipBodyContents = (
              <TreemapTooltipContent
                datum={datum}
                totalValue={totalValue}
                locationLookup={locationLookup}
                currentColorBySelection={currentColorBySelection}
                currentTradeFlow={currentTradeFlow}
                tradeDirection={tradeDirection}
                exporter={exporter}
                importer={importer}
                setQuery={setQuery}
                l10n={l10n}
                view={view}
                productMetadata={productMetadata}
              />
            );
          }
        }
      } else if (view === UIView.Products && productMetadata !== undefined) {
        if (currentVizType === VizType.Tree) {
          tooltipBodyContents = (
            <TreemapTooltipContent
              datum={datum}
              totalValue={totalValue}
              locationLookup={locationLookup}
              currentColorBySelection={currentColorBySelection}
              currentTradeFlow={currentTradeFlow}
              tradeDirection={tradeDirection}
              exporter={exporter}
              importer={importer}
              setQuery={setQuery}
              l10n={l10n}
              view={view}
              productMetadata={productMetadata}
            />
          );
        } else {
          let {
            productId,
            year,
            nameEn,
            exportValue,
            importValue,
            topLevelParent,
            productLevel,
            exportRca,
            distance,
            pci,
            shareForYear,
            groupId,
            countryId,
          } = datum;

          let colorMap = getColorMap({ view, productClass });
          let fillColor = colorMap ? colorMap.get(topLevelParent) : undefined;
          let matchingSector = productMetadata
            ? productMetadata.section.find(
                (productSection: any) =>
                  productSection.productId === topLevelParent,
              )
            : undefined;
          let matchingLevelProductMetadata: any;
          if (productLevel == ProductLevel.ProductSection) {
            matchingLevelProductMetadata =
              productMetadata && productMetadata.section;
          } else if (productLevel == ProductLevel.Product2digit) {
            matchingLevelProductMetadata =
              productMetadata && productMetadata.twoDigit;
          } else if (productLevel == ProductLevel.Product4digit) {
            matchingLevelProductMetadata =
              productMetadata && productMetadata.fourDigit;
          } else if (productLevel == ProductLevel.Product6digit) {
            matchingLevelProductMetadata =
              productMetadata && productMetadata.sixDigit;
          }

          let matchingProductDatum = matchingLevelProductMetadata
            ? matchingLevelProductMetadata.find(
                (productDatum: any) => productDatum.productId === productId,
              )
            : undefined;
          let productCodeLabelValue =
            matchingProductDatum &&
            `${matchingProductDatum.code} ${productClass}`;

          tooltipHeader = (
            <div>
              {nameEn} |{" "}
              <ProductCodeLabel>{productCodeLabelValue}</ProductCodeLabel>
            </div>
          );

          let valueToUse;
          let labelToUse;
          let valueFormatterToUse;

          if (
            currentVizType === VizType.OverTime &&
            currentOverTimeLayout === OverTimeLayoutOption.Share
          ) {
            if (shareForYear !== undefined) {
              valueToUse = shareForYear;
              labelToUse = "Share";
              valueFormatterToUse = format(".2%");
            } else {
              valueToUse = undefined;
              labelToUse = undefined;
              valueFormatterToUse = undefined;
            }
          } else {
            if (tradeDirection === TradeDirection.Exports) {
              // valueToUse = exportValue;
              valueToUse = computeMonetaryValueByTradeFlow({
                datum,
                tradeFlow: currentTradeFlow,
                tradeDirection,
              });
              labelToUse = netGrossLabelModifier
                ? `${netGrossLabelModifier} Export Value`
                : "Export Value";
            } else if (tradeDirection === TradeDirection.Imports) {
              valueToUse = computeMonetaryValueByTradeFlow({
                datum,
                tradeFlow: currentTradeFlow,
                tradeDirection,
              });
              labelToUse = netGrossLabelModifier
                ? `${netGrossLabelModifier} Import Value`
                : "Import Value";
            }
            valueFormatterToUse = (value: any) => "$" + formatTotalValue(value);
          }

          exploreMoreLabel = "For this product explore:";

          const toggleShowMore = () => setShowMore(!showMore);

          if (currentVizType === VizType.OverTime && productId && year) {
            // This tooltip case is for Over Time chart, where we have both productId and year to display
            tooltipBodyContents = (
              <>
                {matchingSector && (
                  <TooltipBodyRow>
                    <TooltipBodyRowLabel>Sector:</TooltipBodyRowLabel>
                    <TooltipBodyRowValue>
                      <SectorLabelWithColorIcon $fillColor={fillColor}>
                        {matchingSector && matchingSector.nameShortEn}
                      </SectorLabelWithColorIcon>
                    </TooltipBodyRowValue>
                  </TooltipBodyRow>
                )}
                <TooltipBodyRow>
                  <TooltipBodyRowLabel>{labelToUse}:</TooltipBodyRowLabel>
                  <TooltipBodyRowValue>
                    {valueFormatterToUse && valueFormatterToUse(valueToUse)}
                  </TooltipBodyRowValue>
                </TooltipBodyRow>
                <TooltipBodyRow>
                  <TooltipBodyRowLabel>Year:</TooltipBodyRowLabel>
                  <TooltipBodyRowValue>{year}</TooltipBodyRowValue>
                </TooltipBodyRow>
              </>
            );
          } else if (currentVizType === VizType.MarketShare) {
            let { year, dataForYear } = datum;

            tooltipHeader = `Year: ${year}`;

            matchingLevelProductMetadata =
              productMetadata && productMetadata.section;

            const formatAsPercentage = (value: number): string => {
              let valueAsPercentage = value * 100;
              if (valueAsPercentage == 0) {
                return format(".0%")(value);
              } else {
                return format(".2%")(value);
              }
            };

            let useColorMap = getColorMap({ view, productClass });

            tooltipBodyContents = (
              <>
                {dataForYear &&
                  dataForYear
                    .sort((productA: any, productB: any) => {
                      return (
                        productB.globalMarketShare - productA.globalMarketShare
                      );
                    })
                    .map((productDatumForYear: any) => {
                      let sectorProductId = productDatumForYear.productId;
                      let globalMarketShare =
                        productDatumForYear.globalMarketShare;
                      let matchingProductDatum = matchingLevelProductMetadata
                        ? matchingLevelProductMetadata.find(
                            (productDatum: any) =>
                              productDatum.productId === sectorProductId,
                          )
                        : undefined;

                      let nameShortEn;
                      if (matchingProductDatum)
                        nameShortEn = matchingProductDatum.nameShortEn;

                      let sectorColor;
                      if (useColorMap && useColorMap.has(sectorProductId)) {
                        sectorColor = useColorMap.get(sectorProductId);
                      } else {
                        sectorColor = undefined;
                      }

                      let sectorColorDot = sectorColor ? (
                        <SectorColorDot $fillColor={sectorColor} />
                      ) : null;

                      return (
                        <TooltipBodyRow>
                          <TooltipBodyRowLabel>
                            {sectorColorDot}
                            {nameShortEn}
                          </TooltipBodyRowLabel>
                          <TooltipBodyRowValue>
                            {formatAsPercentage(globalMarketShare)}
                          </TooltipBodyRowValue>
                        </TooltipBodyRow>
                      );
                    })}
              </>
            );
          } else {
            let sectorFillColor =
              currentColorBySelection === ColorBy.Sector
                ? fillColor
                : undefined;
            let pciFillColor =
              currentColorBySelection === ColorBy.Complexity &&
              currentVizType === VizType.Tree
                ? complexityColorScale(pci)
                : undefined;

            let pciContentRow = (
              <TooltipBodyRow>
                <TooltipBodyRowLabel>
                  <Tooltip
                    explanation={l10n.getString("settings-pci-tooltipText")}
                    title={l10n.getString("settings-pci-title")}
                    inlineTooltip={true}
                  >
                    {l10n.getString("settings-pci-title")}
                  </Tooltip>
                </TooltipBodyRowLabel>
                <TooltipBodyRowValue>
                  <SectorLabelWithColorIcon $fillColor={pciFillColor}>
                    {format(",.3f")(pci)}
                  </SectorLabelWithColorIcon>
                </TooltipBodyRowValue>
              </TooltipBodyRow>
            );
            if (!showMore) {
              const lid = countryId || groupId;

              const locationName =
                locationLookup.get(lid)?.nameEn ||
                locationLookup.get(lid)?.groupName;

              // The first frame of the product tooltip: basic information
              tooltipBodyContents = (
                <>
                  {matchingSector && (
                    <TooltipBodyRow>
                      <TooltipBodyRowLabel>Sector:</TooltipBodyRowLabel>
                      <TooltipBodyRowValue>
                        <SectorLabelWithColorIcon $fillColor={sectorFillColor}>
                          {matchingSector.nameShortEn}
                        </SectorLabelWithColorIcon>
                      </TooltipBodyRowValue>
                    </TooltipBodyRow>
                  )}
                  <TooltipBodyRow>
                    <TooltipBodyRowLabel>{labelToUse}:</TooltipBodyRowLabel>
                    <TooltipBodyRowValue>
                      ${formatTotalValue(valueToUse)}
                    </TooltipBodyRowValue>
                  </TooltipBodyRow>
                  <TooltipBodyRow>
                    <TooltipBodyRowLabel>Share:</TooltipBodyRowLabel>
                    <TooltipBodyRowValue>
                      {totalValue &&
                        formatAsPercentage(valueToUse / totalValue)}
                      %
                    </TooltipBodyRowValue>
                  </TooltipBodyRow>
                  {/* If current ColorBy selection is Complexity, display PCI value on first frame of tooltip; else, show on second frame */}
                  {currentColorBySelection === ColorBy.Complexity
                    ? pciContentRow
                    : null}
                  {productLevel === ProductLevel.Product4digit && (
                    <TooltipBodyRow>
                      <TooltipBodyRowLabel>
                        <ShowMoreLessButton onClick={toggleShowMore}>
                          Show more »
                        </ShowMoreLessButton>
                      </TooltipBodyRowLabel>
                    </TooltipBodyRow>
                  )}
                  <TooltipExploreMoreContainer>
                    <h2>{exploreMoreLabel}</h2>
                    <TooltipExploreMoreButton
                      onClick={() =>
                        setQuery({
                          view: "markets",
                          importer: worldGroupDatum.groupId,
                          exporter: "",
                          product: productId,
                          colorBy: undefined,
                        })
                      }
                    >
                      Where did World export this product to?
                    </TooltipExploreMoreButton>

                    <TooltipExploreMoreButton
                      onClick={() =>
                        setQuery({
                          view: "markets",
                          exporter: worldGroupDatum.groupId,
                          importer: "",
                          product: productId,
                          colorBy: undefined,
                        })
                      }
                    >
                      Where did World export this product to?
                    </TooltipExploreMoreButton>
                  </TooltipExploreMoreContainer>
                </>
              );
            } else if (showMore) {
              // The second frame of the product tooltip: detailed information, e.g., complexity
              tooltipBodyContents = (
                <>
                  <TooltipBodyRow>
                    <TooltipBodyRowLabel>
                      <Tooltip
                        explanation={l10n.getString("settings-rca-tooltipText")}
                        title={l10n.getString("settings-rca-title")}
                        inlineTooltip={true}
                      >
                        {l10n.getString("settings-rca-title")}
                      </Tooltip>
                    </TooltipBodyRowLabel>
                    <TooltipBodyRowValue>
                      {format(",.2f")(exportRca)}
                    </TooltipBodyRowValue>
                  </TooltipBodyRow>
                  <TooltipBodyRow>
                    <TooltipBodyRowLabel>Distance:</TooltipBodyRowLabel>
                    <TooltipBodyRowValue>
                      {format(",.3f")(distance)}
                    </TooltipBodyRowValue>
                  </TooltipBodyRow>
                  {/* If current ColorBy selection is Sector, display PCI value on second frame of tooltip; else, it will be placed on first frame */}
                  {currentColorBySelection === ColorBy.Sector
                    ? pciContentRow
                    : null}
                  <TooltipBodyRow>
                    <TooltipBodyRowLabel>
                      <ShowMoreLessButton onClick={toggleShowMore}>
                        Show less «
                      </ShowMoreLessButton>
                    </TooltipBodyRowLabel>
                  </TooltipBodyRow>
                </>
              );
            }
          }
        }
      } else {
        tooltipBodyContents = null;
      }
    } else {
      tooltipBodyContents = null;
    }

    const handleTooltipClick = (e: React.MouseEvent<HTMLSpanElement>) => {
      e.stopPropagation(); // Stop this click event from firing on tooltip GenericSpan container
    };

    const handleTooltipMouseMoveCapture = (
      e: React.MouseEvent<HTMLSpanElement>,
    ) => {
      e.stopPropagation();
    };

    if (datum !== undefined && tooltipBodyContents !== null) {
      return (
        <TooltipContentsContainer
          onClick={handleTooltipClick}
          onMouseMoveCapture={handleTooltipMouseMoveCapture}
          id={tooltipElementReferenceId}
        >
          <TooltipHeader>{tooltipHeader}</TooltipHeader>
          <TooltipBody>{tooltipBodyContents}</TooltipBody>
        </TooltipContentsContainer>
      );
    } else {
      return <></>;
    }
  },
);

const TreemapTooltipContent = memo(
  ({
    datum,
    totalValue,
    locationLookup,
    currentColorBySelection,
    currentTradeFlow,
    tradeDirection,
    exporter,
    importer,
    setQuery,
    l10n,
    view,
    productMetadata,
  }: {
    datum: any;
    totalValue: number;
    locationLookup: any;
    currentColorBySelection: ColorBy;
    currentTradeFlow: TradeFlow;
    tradeDirection: TradeDirection;
    exporter: string;
    importer: string;
    setQuery: any;
    l10n: any;
    view: UIView;
    productMetadata: any;
  }) => {
    const [{ product: currentProduct }] = usePageQueryParams();
    const [showMore, setShowMore] = useState<boolean>(false);
    const toggleShowMore = () => setShowMore(!showMore);
    const valueFormatterToUse = (value: number) =>
      `$${formatTotalValue(value)}`;

    const {
      nameEn,
      exportValue,
      topLevelParent,
      productLevel,
      exportRca,
      distance,
      pci,
      productId,
      countryId,
      groupId,
    } = datum;

    const { exporterIsWorld, importerIsWorld } = getLocationQualifiers({
      exporter,
      importer,
    });

    let colorMap = getColorMap({
      view: UIView.Products,
      productClass: ProductClass.HS92Products,
    });
    let fillColor = colorMap ? colorMap.get(topLevelParent) : undefined;

    const matchingSector = productMetadata?.section.find(
      (productSection: any) => productSection.productId === topLevelParent,
    );

    const valueToUse = computeMonetaryValueByTradeFlow({
      datum,
      tradeFlow: currentTradeFlow,
      tradeDirection,
    });

    const sectorFillColor =
      currentColorBySelection === ColorBy.Sector ? fillColor : undefined;
    const pciFillColor =
      currentColorBySelection === ColorBy.Complexity
        ? complexityColorScale(pci)
        : undefined;

    const pciContentRow = (
      <TooltipBodyRow>
        <TooltipBodyRowLabel>
          <Tooltip
            explanation={l10n.getString("settings-pci-tooltipText")}
            title={l10n.getString("settings-pci-title")}
            inlineTooltip={true}
          >
            {l10n.getString("settings-pci-title")}
          </Tooltip>
        </TooltipBodyRowLabel>
        <TooltipBodyRowValue>
          <SectorLabelWithColorIcon $fillColor={pciFillColor}>
            {format(",.3f")(pci)}
          </SectorLabelWithColorIcon>
        </TooltipBodyRowValue>
      </TooltipBodyRow>
    );

    const ProductViewTooltipButtons = useMemo(
      () =>
        ({
          locationName,
          productId,
          productName,
          exporter,
          importer,
          locationLookup,
          setQuery,
        }: {
          locationName: string;
          productId: string;
          productName?: string;
          exporter: string;
          importer: string;
          locationLookup: Map<string, any>;
          setQuery: any;
        }) => {
          const isWorldExporter =
            exporter === worldGroupDatum.groupId &&
            importer === worldGroupDatum.groupId;
          const isWorldImporter =
            importer === worldGroupDatum.groupId &&
            exporter === worldGroupDatum.groupId;
          const isWorldBoth = isWorldExporter || isWorldImporter;
          const exporterName =
            locationLookup.get(exporter)?.nameEn ||
            locationLookup.get(exporter)?.groupName;
          const importerName =
            locationLookup.get(importer)?.nameEn ||
            locationLookup.get(importer)?.groupName;
          // Case 1: World is either importer or exporter (or both)
          if (isWorldBoth) {
            return (
              <TooltipExploreMoreContainer>
                <h2>For this product explore:</h2>
                <TooltipExploreMoreButton
                  onClick={() =>
                    setQuery({
                      view: "markets",
                      exporter: worldGroupDatum.groupId,
                      importer: "",
                      product: productId,
                      colorBy: undefined,
                    })
                  }
                >
                  Who exported this product?
                </TooltipExploreMoreButton>
                <TooltipExploreMoreButton
                  onClick={() =>
                    setQuery({
                      view: "markets",
                      exporter: "",
                      importer: worldGroupDatum.groupId,
                      product: productId,
                      colorBy: undefined,
                    })
                  }
                >
                  Who imported this product?
                </TooltipExploreMoreButton>
              </TooltipExploreMoreContainer>
            );
          }

          // Case 2: Specific exporter, World importer
          if (!isWorldBoth && importer === worldGroupDatum.groupId) {
            return (
              <TooltipExploreMoreContainer>
                <h2>For this product explore:</h2>
                <TooltipExploreMoreButton
                  onClick={() =>
                    setQuery({
                      view: "markets",
                      exporter: worldGroupDatum.groupId,
                      importer: "",
                      product: productId,
                      colorBy: undefined,
                    })
                  }
                >
                  Who exported this product?
                </TooltipExploreMoreButton>
                <TooltipExploreMoreButton
                  onClick={() =>
                    setQuery({
                      view: "markets",
                      exporter: exporter,
                      importer: worldGroupDatum.groupId,
                      product: productId,
                      colorBy: undefined,
                    })
                  }
                >
                  {`Where did ${exporterName} export this product to?`}
                </TooltipExploreMoreButton>
              </TooltipExploreMoreContainer>
            );
          }

          // Case 3: World exporter, Specific importer
          if (exporter === worldGroupDatum.groupId && !importerIsWorld) {
            return (
              <TooltipExploreMoreContainer>
                <h2>For this product explore:</h2>
                <TooltipExploreMoreButton
                  onClick={() =>
                    setQuery({
                      view: "markets",
                      exporter: "",
                      importer: worldGroupDatum.groupId,
                      product: productId,
                      colorBy: undefined,
                    })
                  }
                >
                  Who imported this product?
                </TooltipExploreMoreButton>
                <TooltipExploreMoreButton
                  onClick={() =>
                    setQuery({
                      view: "markets",
                      exporter: worldGroupDatum.groupId,
                      importer: importer,
                      product: productId,
                      colorBy: undefined,
                    })
                  }
                >
                  {`Where did ${importerName} import this product from?`}
                </TooltipExploreMoreButton>
              </TooltipExploreMoreContainer>
            );
          }

          // Case 4: Specific exporter and importer
          return (
            <TooltipExploreMoreContainer>
              <h2>For this product explore:</h2>
              <TooltipExploreMoreButton
                onClick={() =>
                  setQuery({
                    view: "markets",
                    exporter: exporter,
                    importer: worldGroupDatum.groupId,
                    product: productId,
                    colorBy: undefined,
                  })
                }
              >
                {`Where did ${exporterName} export this product to?`}
              </TooltipExploreMoreButton>
              <TooltipExploreMoreButton
                onClick={() =>
                  setQuery({
                    view: "markets",
                    exporter: worldGroupDatum.groupId,
                    importer: importer,
                    product: productId,
                    colorBy: undefined,
                  })
                }
              >
                {`Where did ${importerName} import this product from?`}
              </TooltipExploreMoreButton>
            </TooltipExploreMoreContainer>
          );
        },
      [importerIsWorld],
    );

    const LocationViewTooltipButtons = ({
      locationName,
      productId,
      productName,
      exporter,
      importer,
      locationLookup,
      setQuery,
      lid,
      valueFormatterToUse,
    }: {
      locationName: string;
      productId: string;
      productName?: string;
      exporter: string;
      importer: string;
      locationLookup: Map<string, any>;
      setQuery: any;
      lid: string;
      valueFormatterToUse: any;
    }) => {
      const isWorldExporter =
        exporter === worldGroupDatum.groupId && importer === "";
      const isWorldImporter =
        importer === worldGroupDatum.groupId && exporter === "";
      const isWorldBoth = isWorldExporter || isWorldImporter;
      const isAllProducts = productId === allProductsDatum.productId;

      // Case 1: World + All Products
      if (isWorldBoth && isAllProducts) {
        const isExporting = isWorldExporter; // If World is importer, we're looking at exports
        return (
          <TooltipExploreMoreContainer>
            <h2>For this location explore:</h2>
            <TooltipExploreMoreButton
              onClick={() =>
                setQuery({
                  view: "products",
                  exporter: isExporting ? lid : worldGroupDatum.groupId,
                  importer: isExporting ? worldGroupDatum.groupId : lid,
                  product: allProductsDatum.productId,
                  colorBy: undefined,
                })
              }
            >
              {`What products did ${locationName} ${isExporting ? "export" : "import"}?`}
            </TooltipExploreMoreButton>
            <TooltipExploreMoreButton
              onClick={() =>
                setQuery({
                  view: "markets",
                  exporter: isExporting ? lid : worldGroupDatum.groupId,
                  importer: isExporting ? worldGroupDatum.groupId : lid,
                  product: allProductsDatum.productId,
                  colorBy: undefined,
                })
              }
            >
              {`Where did ${locationName} ${isExporting ? "export to" : "import from"}?`}
            </TooltipExploreMoreButton>
          </TooltipExploreMoreContainer>
        );
      }
      // Case 2: World + Specific Product
      if (isWorldBoth && !isAllProducts) {
        const isExporting = isWorldExporter; // If World is importer, we're looking at exports
        return (
          <TooltipExploreMoreContainer>
            <h2>For this location explore:</h2>
            <TooltipExploreMoreButton
              onClick={() =>
                setQuery({
                  view: "markets",
                  exporter: isExporting ? lid : worldGroupDatum.groupId,
                  importer: isExporting ? worldGroupDatum.groupId : lid,
                  product: productId,
                  colorBy: undefined,
                })
              }
            >
              {`Where did ${locationName} ${isExporting ? "export" : "import"} ${productName} ${isExporting ? "to" : "from"}?`}
            </TooltipExploreMoreButton>
            <TooltipExploreMoreButton
              onClick={() =>
                setQuery({
                  view: "markets",
                  exporter: isExporting ? lid : worldGroupDatum.groupId,
                  importer: isExporting ? worldGroupDatum.groupId : lid,
                  product: allProductsDatum.productId,
                  colorBy: undefined,
                })
              }
            >
              {`Where  did ${locationName} ${isExporting ? "export to" : "import from"}?`}
            </TooltipExploreMoreButton>
          </TooltipExploreMoreContainer>
        );
      }

      // Case 3: Specific Countries + All Products
      if (!isWorldBoth && isAllProducts) {
        const isExporting = importer === worldGroupDatum.groupId; // If World is importer, we're looking at exports
        const selectedCountryName = isExporting
          ? locationLookup.get(exporter)?.nameEn ||
            locationLookup.get(exporter)?.groupName
          : locationLookup.get(importer)?.nameEn ||
            locationLookup.get(importer)?.groupName;
        const selectedCountryId = isExporting ? exporter : importer;
        return (
          <TooltipExploreMoreContainer>
            <h2>For this location explore:</h2>
            <TooltipExploreMoreButton
              onClick={() =>
                setQuery({
                  view: "products",
                  exporter: isExporting ? selectedCountryId : lid,
                  importer: isExporting ? lid : selectedCountryId,
                  product: allProductsDatum.productId,
                  colorBy: undefined,
                })
              }
            >
              {`What products did ${selectedCountryName} ${isExporting ? "export" : "import"} ${isExporting ? "to" : "from"} ${locationName}?`}
            </TooltipExploreMoreButton>
            <TooltipExploreMoreButton
              onClick={() =>
                setQuery({
                  view: "markets",
                  exporter: isExporting ? lid : worldGroupDatum.groupId,
                  importer: isExporting ? worldGroupDatum.groupId : lid,
                  product: allProductsDatum.productId,
                  colorBy: undefined,
                })
              }
            >
              {`Where did ${locationName} ${isExporting ? "export to" : "import from"}?`}
            </TooltipExploreMoreButton>
          </TooltipExploreMoreContainer>
        );
      }

      // Case 4: Specific Countries + Specific Product
      const isExporting = importer === worldGroupDatum.groupId;

      return (
        <TooltipExploreMoreContainer>
          <h2>For this location explore:</h2>
          <TooltipExploreMoreButton
            onClick={() =>
              setQuery({
                view: "markets",
                exporter: isExporting ? lid : worldGroupDatum.groupId,
                importer: isExporting ? worldGroupDatum.groupId : lid,
                product: productId,
                colorBy: undefined,
              })
            }
          >
            {`Where did ${locationName} ${isExporting ? "export" : "import"} ${productName} ${isExporting ? "to" : "from"}?`}
          </TooltipExploreMoreButton>
          <TooltipExploreMoreButton
            onClick={() =>
              setQuery({
                view: "markets", // Changed from "products" to "markets" to show destinations/sources
                exporter: isExporting ? lid : worldGroupDatum.groupId,
                importer: isExporting ? worldGroupDatum.groupId : lid,
                product: allProductsDatum.productId,
                colorBy: undefined,
              })
            }
          >
            {`Where did ${locationName} ${isExporting ? "export to" : "import from"}?`}
          </TooltipExploreMoreButton>
        </TooltipExploreMoreContainer>
      );
    };

    // Get the product name from metadata
    const currentProductMetadata =
      productMetadata?.fourDigit?.find(
        (p: any) => p.productId === currentProduct,
      ) ||
      productMetadata?.section?.find(
        (p: any) => p.productId === currentProduct,
      );
    const location = useLocation();

    const tooltipButtons = useMemo(() => {
      const currentVizType = location.pathname.split("/")[2] as VizType;
      if (currentVizType !== VizType.Tree) {
        return null;
      }
      const locationId = datum?.partnerCountryId;
      const hoveredLocationName = datum?.nameEn;

      const currentProductName =
        currentProductMetadata?.nameShortEn || currentProductMetadata?.nameEn;

      // Check if we're in Markets view
      if (view === UIView.Markets) {
        return (
          <LocationViewTooltipButtons
            locationName={hoveredLocationName}
            productId={currentProduct}
            productName={currentProductName}
            exporter={exporter}
            importer={importer}
            locationLookup={locationLookup}
            setQuery={setQuery}
            lid={locationId}
            valueFormatterToUse={valueFormatterToUse}
          />
        );
      }

      // If not Markets view, render ProductViewTooltipButtons
      return (
        <ProductViewTooltipButtons
          locationName={hoveredLocationName}
          productId={productId}
          productName={currentProductName}
          exporter={exporter}
          importer={importer}
          locationLookup={locationLookup}
          setQuery={setQuery}
        />
      );
    }, [
      datum,
      currentProduct,
      currentProductMetadata,
      view,
      locationLookup,
      exporter,
      importer,
      setQuery,
    ]);

    if (!showMore) {
      return (
        <>
          {matchingSector && (
            <TooltipBodyRow>
              <TooltipBodyRowLabel>Sector:</TooltipBodyRowLabel>
              <TooltipBodyRowValue>
                <SectorLabelWithColorIcon $fillColor={sectorFillColor}>
                  {matchingSector && matchingSector.nameShortEn}
                </SectorLabelWithColorIcon>
              </TooltipBodyRowValue>
            </TooltipBodyRow>
          )}
          <TooltipBodyRow>
            <TooltipBodyRowLabel>
              {exporterIsWorld && importer === ""
                ? "Export Value"
                : importerIsWorld && exporter === ""
                  ? "Import Value"
                  : exporterIsWorld
                    ? "Import Value"
                    : "Export Value"}
              :
            </TooltipBodyRowLabel>
            <TooltipBodyRowValue>
              {valueFormatterToUse && valueFormatterToUse(valueToUse)}
            </TooltipBodyRowValue>
          </TooltipBodyRow>
          <TooltipBodyRow>
            <TooltipBodyRowLabel>Share:</TooltipBodyRowLabel>
            <TooltipBodyRowValue>
              {totalValue && formatAsPercentage(valueToUse / totalValue)}%
            </TooltipBodyRowValue>
          </TooltipBodyRow>
          {currentColorBySelection === ColorBy.Complexity
            ? pciContentRow
            : null}
          {productLevel === ProductLevel.Product4digit && (
            <TooltipBodyRow>
              <TooltipBodyRowLabel>
                <ShowMoreLessButton onClick={toggleShowMore}>
                  Show more »
                </ShowMoreLessButton>
              </TooltipBodyRowLabel>
            </TooltipBodyRow>
          )}
          {tooltipButtons}
        </>
      );
    } else {
      return (
        <>
          <TooltipBodyRow>
            <TooltipBodyRowLabel>
              <Tooltip
                explanation={l10n.getString("settings-rca-tooltipText")}
                title={l10n.getString("settings-rca-title")}
                inlineTooltip={true}
              >
                {l10n.getString("settings-rca-title")}
              </Tooltip>
            </TooltipBodyRowLabel>
            <TooltipBodyRowValue>
              {format(",.2f")(exportRca)}
            </TooltipBodyRowValue>
          </TooltipBodyRow>
          <TooltipBodyRow>
            <TooltipBodyRowLabel>Distance:</TooltipBodyRowLabel>
            <TooltipBodyRowValue>
              {format(",.3f")(distance)}
            </TooltipBodyRowValue>
          </TooltipBodyRow>
          {currentColorBySelection === ColorBy.Sector ? pciContentRow : null}
          <TooltipBodyRow>
            <TooltipBodyRowLabel>
              <ShowMoreLessButton onClick={toggleShowMore}>
                Show less «
              </ShowMoreLessButton>
            </TooltipBodyRowLabel>
          </TooltipBodyRow>
        </>
      );
    }
  },
);

const Tooltip = (props: Props) => {
  const {
    explanation,
    children,
    cursor,
    theme,
    tooltipPosition,
    overrideStyles,
    delay,
    inlineTooltip,
    titleTooltip,
    isFixed,
    isFixedCoords,
    snapToPosition,
    vizType,
    clearHighlightedItem,
  } = props;
  const rootEl = useRef<HTMLDivElement | null>(null);
  const tooltipEl = useRef<HTMLDivElement | null>(null);
  const overlayPortalContainerNodeRef = useRef<HTMLElement | null>(null);

  const [isTooltipShown, setIsTooltipShown] = useState<boolean>(
    isFixed && isFixed === true ? true : false,
  );
  const [coords, setCoords] = useState<{ top: number; left: number }>(
    isFixedCoords
      ? { left: isFixedCoords[0], top: isFixedCoords[1] }
      : { top: 0, left: 0 },
  );
  const [isTooltipFixed, setIsTooltipFixed] = useState<boolean>(
    isFixed === undefined ? false : isFixed,
  );

  const clearTooltipAndHighlights = () => {
    setIsTooltipFixed(false);
    setIsTooltipShown(false);
    if (clearHighlightedItem) clearHighlightedItem();
  };

  useEffect(() => {
    const handleGlobalClick = (e: MouseEvent) => {
      if (
        isTooltipFixed &&
        tooltipEl.current &&
        !tooltipEl.current.contains(e.target as Node)
      ) {
        clearTooltipAndHighlights();
      }
    };

    if (isTooltipFixed) {
      document.addEventListener("click", handleGlobalClick);
    }

    return () => {
      document.removeEventListener("click", handleGlobalClick);
    };
  }, [isTooltipFixed, clearHighlightedItem]);

  useEffect(() => {
    if (isFixedCoords) {
      setCoords({ left: isFixedCoords[0], top: isFixedCoords[1] });
    } else {
      setCoords({ top: 0, left: 0 });
    }
  }, [isFixedCoords]);

  useEffect(() => {
    if (isFixed !== undefined) {
      if (isFixed === true) {
        setIsTooltipShown(true);
        setIsTooltipFixed(true);
      }
      if (isFixedCoords)
        setCoords({ left: isFixedCoords[0], top: isFixedCoords[1] });
    }
  }, [isFixed, isFixedCoords]);

  useLayoutEffect(() => {
    const node = document.querySelector<HTMLElement>(
      `#${overlayPortalContainerId}`,
    );
    overlayPortalContainerNodeRef.current = node;
    const tooltipElm = tooltipEl.current;
    const rootElm = rootEl.current;

    if (
      explanation !== null &&
      tooltipElm !== null &&
      rootElm !== null &&
      tooltipElm.childNodes.length > 1
    ) {
      if (Children.count(explanation) > 0) {
        const { top, left } = coords;
        const tooltipSpacing = 15;
        const tooltipHeight = tooltipElm.offsetHeight;
        const tooltipWidth = tooltipElm.offsetWidth;
        let tooltipTopValue, tooltipLeftValue;

        // Snapping tooltip to line position, in over time and market share
        if (snapToPosition) {
          if (vizType && vizType === VizType.OverTime) {
            tooltipTopValue = top - tooltipSpacing / 2 - tooltipHeight;
            tooltipLeftValue = left - tooltipWidth / 2;

            tooltipElm.classList.remove(leftArrowClassName);
            tooltipElm.classList.remove(rightArrowClassName);
          } else {
            tooltipTopValue = top - tooltipHeight / 2;
            tooltipLeftValue = left + tooltipSpacing;

            if (
              tooltipLeftValue + (tooltipWidth + tooltipSpacing) >
              window.innerWidth
            ) {
              tooltipLeftValue = left - tooltipWidth - tooltipSpacing;

              tooltipElm.classList.add(rightArrowClassName);
              tooltipElm.classList.remove(leftArrowClassName);
            } else {
              tooltipElm.classList.add(leftArrowClassName);
              tooltipElm.classList.remove(rightArrowClassName);
            }
          }
        } else {
          tooltipTopValue = top - tooltipSpacing - tooltipHeight;
          tooltipLeftValue = left - tooltipWidth / 2;
          tooltipElm.classList.remove(leftArrowClassName);
          tooltipElm.classList.remove(rightArrowClassName);
        }

        if (tooltipTopValue < 0 || tooltipPosition === TooltipPosition.Bottom) {
          // tooltip will be above the window
          tooltipTopValue = top + tooltipSpacing * 2;
          tooltipElm.classList.add(flipArrowClassName);
        } else {
          tooltipElm.classList.remove(flipArrowClassName);
        }
        if (tooltipLeftValue < tooltipSpacing) {
          tooltipLeftValue = tooltipSpacing;
        }
        if (
          tooltipLeftValue + (tooltipWidth + tooltipSpacing) >
          window.innerWidth
        ) {
          // tooltip will exceed the windows width
          tooltipLeftValue = window.innerWidth - tooltipWidth - tooltipSpacing;
        }
        if (window.innerWidth - left < tooltipSpacing * 3) {
          // tooltip is at the far end of the screen
          tooltipElm.classList.add(farEndOfScreenToggleClass);
        } else {
          tooltipElm.classList.remove(farEndOfScreenToggleClass);
        }
        tooltipElm.style.cssText = `
          left: ${tooltipLeftValue}px;
          top: ${tooltipTopValue}px;
          opacity: 1;
        `;
      } else {
        tooltipElm.style.cssText = `
          opacity: 0;
        `;
      }
    } else {
    }
  }, [
    explanation,
    isTooltipShown,
    coords,
    tooltipPosition,
    isTooltipFixed,
    isFixedCoords,
    snapToPosition,
    vizType,
  ]);
  const overlayPortalContainerNode = overlayPortalContainerNodeRef.current;

  const arrow = (
    <ArrowContainer
      $position={tooltipPosition}
      className={arrowContainerClassName}
    >
      <Arrow $theme={theme} $position={tooltipPosition} />
    </ArrowContainer>
  );

  const CloseButton = styled.button`
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: inherit;
    opacity: 0.7;
    z-index: 1;

    &:hover {
      opacity: 1;
    }
  `;

  const TooltipContent = styled.div`
    position: relative;
  `;

  const handleCloseClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    clearTooltipAndHighlights();
  };

  let tooltip: React.ReactPortal | null;

  if (
    isTooltipShown !== false &&
    overlayPortalContainerNode !== null &&
    explanation
  ) {
    tooltip = ReactDOM.createPortal(
      <TooltipBase
        ref={tooltipEl}
        $theme={theme}
        $overrideStyles={overrideStyles}
        $isFixed={isTooltipFixed}
        className={
          tooltipPosition === TooltipPosition.Right
            ? leftArrowClassName
            : undefined
        }
      >
        <TooltipContent>
          {isTooltipFixed && (
            <CloseButton onClick={handleCloseClick}>×</CloseButton>
          )}
          {explanation}
        </TooltipContent>
        {arrow}
      </TooltipBase>,
      overlayPortalContainerNode,
    );
  } else {
    tooltip = null;
  }

  const onMouseEnter = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (!isTooltipFixed) {
      setCoords({ top: e.clientY, left: e.clientX });
      if (delay) {
        timeout = setTimeout(() => setIsTooltipShown(true), delay) as any;
      } else {
        setIsTooltipShown(true);
      }
    }
  };
  const onMouseLeave = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (!isTooltipFixed) {
      setCoords({ top: e.clientY, left: e.clientX });
      clearTimeout(timeout);
      clearTooltipAndHighlights();
    } else {
      e.stopPropagation();
    }
  };

  const onMouseMove = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (!isTooltipShown && explanation) setIsTooltipShown(true);

    if (!isTooltipFixed && isFixedCoords === undefined) {
      setCoords({ top: e.clientY, left: e.clientX });
    } else if (isTooltipFixed) {
      e.stopPropagation();
    }
  };

  const onMouseClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent the global click handler from immediately closing the tooltip
    if (isTooltipFixed || isFixed) {
      clearTooltipAndHighlights();
    } else if (explanation && !isTooltipFixed) {
      setIsTooltipFixed(true);
    }
    if (isTooltipShown) {
      if (clearHighlightedItem) clearHighlightedItem();
    }
  };

  /*
  NOTE: In the following `<GenericSpan>` usage, the alternative event handler of
  `onMouseMoveCapture` is used. This "capture" alternative is used 
  to detect events in the capture direction, i.e., downward from parent to
  child in the tree, before detecting events in the bubbling direction, i.e., upward
  from child to parent.

  This is used for allowing the user to fix the position of the tooltip in the tooltip
  container: after the user clicks inside the container to fix the position of the tooltip,
  we don't want to continue triggering the mouseMove events of the children, which would
  continuously update the contents of the tooltip. (We want the tooltip contents to remain the same!)
  */
  if (children !== undefined) {
    if (inlineTooltip) {
      return (
        <GenericSpanInline
          onMouseEnter={onMouseEnter}
          onMouseMoveCapture={onMouseMove}
          onMouseLeave={onMouseLeave}
          onClick={onMouseClick}
          ref={rootEl}
          style={{ cursor, fontStyle: titleTooltip ? "italic" : "inherit" }}
        >
          {children}
          {tooltip}
        </GenericSpanInline>
      );
    } else {
      return (
        <GenericSpan
          onMouseEnter={onMouseEnter}
          onMouseMoveCapture={onMouseMove}
          onMouseLeave={onMouseLeave}
          onClick={onMouseClick}
          ref={rootEl}
          style={{ cursor }}
        >
          {children}
          {tooltip}
        </GenericSpan>
      );
    }
  } else {
    return (
      <Root
        onMouseEnter={onMouseEnter}
        onMouseMoveCapture={onMouseMove}
        onMouseLeave={onMouseLeave}
        style={{ cursor }}
        ref={rootEl}
      >
        {tooltip}
      </Root>
    );
  }
};

export default memo(Tooltip);
